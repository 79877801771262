$pattern: "/images/pattern.png";
$screenshot-buttons: "/images/screenshot-buttons.svg";

$brand-color: #5EC300;
$main-background-image: url(/images/main_background_image.jpg);
$main-background-shade-top: 1;
$main-background-shade-bottom: 0.25;
$html-background-color: #222;
$secondary-brand-color: #da73ff;
$middle-gradient-color: mix($brand-color, $secondary-brand-color, 95%);

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";

$section-rotation: 5deg;

@import "elements";
@import "layout";
@import "contact";
@import "blog";
@import "forms";
@import "navigation";
@import "footer";
@import "cloudcannon";