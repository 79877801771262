input[type=submit] {
	color: #fff;
	text-decoration: none;
	padding: 20px 30px;
	border-radius: 3px;
	transition: 0.2s ease-in-out;
	border: 1px solid $brand-color;
	background: $brand-color;
	cursor: pointer;
}

input[type=submit]:hover {
	border: 1px solid $brand-color;
	background: $secondary-brand-color;
}

input[type=submit]:active {
	transform: translateY(1px);
}

textarea,
input,
button,
select {
	font-family: inherit;
	font-size: inherit;
}

input[type=submit] {
	margin: 20px 0 0 0;
}

label,
input:not([type='checkbox']),
textarea,
select {
	display: block;
	width: 100%;
	box-sizing: border-box;
}



textarea {
	resize: vertical;
	height: 250px;
}

label {
	margin: 30px 0 10px 0;
	font-size: 1.2em;
}

input,
textarea,
select {
	padding: 20px;
	font-size: 1.2em;
	border-radius: 3px;
}

input,
textarea,
select {
	-webkit-transition: all 0.30s ease-in-out;
	-moz-transition: all 0.30s ease-in-out;
	-ms-transition: all 0.30s ease-in-out;
	outline: none;
	border: 1px solid #DDDDDD;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
select:focus,
textarea:focus {
	box-shadow: 0 0 5px rgba(81, 203, 238, 1);
	border: 1px solid rgba(81, 203, 238, 1);
}
