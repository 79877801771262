.container,
.text-container {
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
}

.text-container {
  max-width: 1000px;
}

.container {
  max-width: 1000px;
}

header {
  color: #fff;
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  a {
    color: #fff;
    text-decoration: none;
    z-index: 1;
    position: relative;

    &:hover {
      text-decoration: none;
    }
  }

  .company-name {
    font-size: 1.7em;
  }
}

.content {
  background: #fff;
  padding: 1px 0 0 0;
  position: relative;
}

.screenshot {
  height: auto;
  display: block;
  margin: 0 auto;
  //box-shadow: 0 1px 0 #ccc, 0 1px 1px #eee;
  border-radius: 2px;
  //padding: 20px 0 0;
  //background: url($screenshot-buttons) 4px 4px no-repeat #DDD;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

section {
  padding: 150px 0;
  background: #fff;
  margin-top: -2px;

  &.diagonal {
    -webkit-transform: skewY(-$section-rotation);
    -moz-transform: skewY(-$section-rotation);
    -ms-transform: skewY(-$section-rotation);
    transform: skewY(-$section-rotation);

    > div {
      -webkit-transform: skewY($section-rotation);
      -moz-transform: skewY($section-rotation);
      -ms-transform: skewY($section-rotation);
      transform: skewY($section-rotation);
    }
  }

  &.hero {
    color: #ffffff;
    background: linear-gradient(
                    rgba(0, 0, 0, $main-background-shade-top),
                    rgba(0, 0, 0, $main-background-shade-bottom)
    ), $main-background-image;
    background-size: cover;

    text-align: left;
    margin-top: -100px;
    padding-top: 250px;


    h2, p {
      max-width: 90%;
    }

    h2 {
      font-size: 3.5rem;
    }

    p {
      font-size: 1.8rem;
      color: #fff;
    }

    a:not(.button) {
      color: #ffffff;
      font-style: italic;
    }
  }

  &.alternate {
    background: #eee;
  }
}

.button {
  padding: 13px 38px;
  border-radius: 3px;
  display: inline-block;
  color: $brand-color;
  text-decoration: none;
  cursor: pointer;
  margin: 5% 0% 0% 5%;
  border: 1px solid #fff;
  // flex: 1 1 25%;
  // overflow: row wrap;
  // background: #fff;
  text-decoration: none !important;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
  }

  &:active {
    opacity: 1;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    transform: translateY(1px);
  }
}

.inline {
  padding: 0% 5% 0% 20%;
  border-radius: 3px;
  display: inline-block;
  color: $brand-color;
  text-decoration: none;
  cursor: pointer;
  margin: 0% 5% 0% 20%;
  border: 1px hidden #fff;
  // flex: 1 1 25%;
  // overflow: row wrap;
  // background: #fff;
  text-decoration: none !important;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
  }

  &:active {
    opacity: 1;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    transform: translateY(1px);
  }
}

.halves {
  display: flex;
  flex-flow: wrap;
  align-items: center;

  > div {
    flex: 1 0 100%;
  }

  @media #{$mid-point} {
    > div {
      flex: 1;
    }

    > div + div {
      padding-left: 20px;
      margin: 0;
    }
  }
}

.aligned-top {
  align-items: flex-start;
}

.client-list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  text-align: center;

  li {
    padding: 0;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    display: flex;
    margin: 20px;
    border-radius: 5px;
    text-align: left;

    @media #{$tablet} {
      flex-direction: column;
      text-align: center;
    }

    @media #{$desktop} {
      flex-direction: row;
      text-align: left;
    }

    > a:hover {
      opacity: 0.9;
    }
  }

  .thumbnail {
    padding: 2rem;

    img {
      width: 10rem;
      border-radius: 50%;
    }
  }

  .details {
    line-height: 1.2;
    flex-grow: 1;    
    flex-basis: 20%;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
  }

  .name {
    font-size: 1.3rem;
    color: $brand-color;
  }

  .filler {
    border: 0;
  }
}

.image-grid {
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  margin: 0 -20px;

  li {
    padding: 0;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 20px;
    border-radius: 5px;
    //text-align: center;
    //border: 1px solid $brand-color;
    line-height: 0;

    @media #{$tablet} {
      flex: 1 1 50%;
    }

    @media #{$desktop} {
      flex: 1 1 25%;
    }

    > a:hover {
      opacity: 0.9;
    }
  }

  .square-image {
    height: 320px;

    img {
      min-height: 100%;
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .details {
    line-height: 1.2;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
  }

  .name {
    font-size: 1.3rem;
    color: $brand-color;
  }

  .position {
    margin-top: 5px;
    color: #fff;
  }

  .filler {
    border: 0;
  }
}

.image-list,
.image-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.patterned {
  background: #222 url($pattern);

  &,
  * {
    color: #fefefe;
  }

  input,
  select,
  option {
    color: #444;
    background-color: #fff;
  }
}

.req {
  color: red;
}

.social-icons {
  width: 100%;
  margin: 10px;
  padding: 0;
  flex: 1 0 180px;

  li {
    list-style: none;
    margin: 15px 0;

    a {
      svg {
        fill: #999;
        margin-right: 10px;
        transition: fill 0.2s ease;
        vertical-align: middle;
        position: relative;
        top: -2px;
        width: 22px;
        height: 22px;
      }

      &:hover {
        text-decoration: none;

        svg {
          fill: #fff;
        }
      }
    }
  }
}